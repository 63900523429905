import 'bootstrap/dist/css/bootstrap.css';
import '../styles/style.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import { ApolloProvider } from "@apollo/client/react";
import { client } from "../../lib/apollo";
import { useEffect } from 'react';
import NProgress from 'nprogress';
import { useRouter } from 'next/router';
import { onFID } from 'web-vitals';
import { SessionProvider } from "next-auth/react"
import { SnackbarProvider } from 'notistack'

function MyApp({ Component, pageProps: { session, ...pageProps } }) {

  useEffect(() => {
    onFID(console.log);
  }, []);

  const router = useRouter();

  NProgress.configure({ showSpinner: false });

  useEffect(() => {
    const handleStart = () => {
      NProgress.start();
    };
    const handleStop = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  return (
    <SnackbarProvider>
      {
        router.pathname.startsWith("/dashboard") ?
          <SessionProvider session={session}>
            <Component {...pageProps} />
          </SessionProvider>
          :
          <ApolloProvider client={client}>
            <Component {...pageProps} />
          </ApolloProvider>
      }
    </SnackbarProvider>
  )
}

export default MyApp
